const STATUS_COLORS = {
  approved: '#10b981', // green
  rejected: '#ef4444', // red
  pending: '#f59e0b', // amber
};

export const getStatusStyle = (status) => ({
  color: STATUS_COLORS[status] || STATUS_COLORS.pending,
});

export const getStatusLabel = (status) =>
      status ? status.charAt(0).toUpperCase() + status.slice(1) : 'Pending';

export const formatDate = (dateString) =>
    dateString ? new Date(dateString).toLocaleDateString() : '-';

export const formatMoney = (amount, currency = 'EUR') =>
    amount ? new Intl.NumberFormat('nl-NL', {style: 'currency', currency}).format(amount) : '-';

export const formatTableRow = (item, t) => {
  const projectId = item.project?.['@id']?.match(/\d+$/)?.[0];

  const baseData = {
    id: item.id.toString(),
    projectId,
    pendingStatus: item.pendingStatus,
    fields: [
      {
        label: `Project ${item.id}`,
      },
      {
        label: item.description?.trim() ? item.description : t('table.no_description'),
        noWrap: true,
        style: {
          maxWidth: '300px',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          color: !item.description?.trim() ? '#666' : 'inherit',
        },
      },
      {
        label: getStatusLabel(item.pendingStatus),
        style: {
          ...getStatusStyle(item.pendingStatus),
          fontWeight: 500,
        },
      },
      {
        label: formatDate(item.became_opportunity_at),
        style: {
          color: item.became_opportunity_at ? 'inherit' : '#666',
        },
      },
    ],
  };

  if (item.pendingStatus === 'approved' && projectId) {
    baseData.moreMenu = [{
      id: 'details',
      text: t('table.actions.details'),
    }];
  }

  return baseData;
};
