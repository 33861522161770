export const ROUTE_ANONYMOUS = {
  LOGIN: '/login',
  LOGIN_TOTP_CODE: '/login-code',
  FORGOTTEN_PASSWORD: '/forgotten-password',
  RESTORE_PASSWORD: '/restore-password',
  CONFIRMATION_LINK: '/confirmation-link',
  PASSWORD_CHANGED: '/password-changed',
  LEAD_SELF_REGISTRATION: '/adding-leads-automatically',
  INVESTOR_SELF_REGISTRATION: '/company-self-registration',
};

export default function getLoginFrontendPath() {
  const currentLocation = document.location.pathname;
  if (!Object.values(ROUTE_ANONYMOUS).find((item)=>currentLocation.startsWith(item))) {
    return `${document.location.origin}/login?redirectTo=${currentLocation}`;
  } else {
    return `${document.location.origin}${currentLocation}`;
  }
}
