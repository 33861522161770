import Api from '../../../helpers/api/Api';

export default function changeRequestStatus(id, pendingStatus) {
  return new Promise(function(resolve, reject) {
    Api.post(`/pending_investments/${id}/change_status`, {
      pendingStatus,
    })
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
  });
}
