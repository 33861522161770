import './Messages.scss';

import {Button} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import PropTypes from 'prop-types';
import * as React from 'react';
import {useCallback, useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useLocation, useNavigate} from 'react-router-dom';

import {useStateValue} from '../../state/state';
import getMessages from './api/getMessages';
import getRecipients from './api/getRecipients';
import sendMessage from './api/sendMessage';

export default function Messages({projectId}) {
  const {t} = useTranslation('page_available_investments');
  const [{user}] = useStateValue();
  const navigate = useNavigate();
  const location = useLocation();
  const [messages, setMessages] = useState([]);
  const [recipients, setRecipients] = useState([]);
  const [selectedRecipient, setSelectedRecipient] = useState(null);
  const [messageText, setMessageText] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [messageTitle, setMessageTitle] = useState('');
  const [isSending, setIsSending] = useState(false);
  const messagesContainerRef = useRef(null);
  const [isRefetching, setIsRefetching] = useState(false);

  // Get user ID from URL on component mount
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const userId = searchParams.get('user');
    if (userId && recipients.length > 0) {
      const recipient = recipients.find((r) => r.id.toString() === userId);
      if (recipient) {
        setSelectedRecipient(recipient);
      }
    }
  }, [location.search, recipients]);

  // Fetch recipients when component mounts
  useEffect(() => {
    getRecipients(projectId)
        .then((response) => {
          // If response is using hydra format
          const recipientsData = response['hydra:member'] || response || [];
          setRecipients(recipientsData);
        })
        .catch((error) => {
          console.error('Failed to fetch recipients:', error);
        });
  }, [projectId]);

  const scrollToBottom = useCallback(() => {
    if (messagesContainerRef.current) {
      const container = messagesContainerRef.current;
      container.scrollTo({
        top: container.scrollHeight,
        behavior: 'smooth',
      });
    }
  }, []);

  const fetchMessages = useCallback(() => {
    if (!selectedRecipient) {
      setMessages([]);
      return;
    }

    const loadingState = messages.length === 0 ? setIsLoading : setIsRefetching;
    loadingState(true);

    getMessages(projectId, selectedRecipient.id)
        .then((response) => {
          setMessages(response['hydra:member'] || []);
        })
        .catch((error) => {
          console.error('Failed to fetch messages:', error);
        })
        .finally(() => {
          loadingState(false);
        });
  }, [projectId, selectedRecipient, messages.length]);

  useEffect(() => {
    fetchMessages();
  }, [fetchMessages]);

  const handleRecipientSelect = (recipient) => {
    setSelectedRecipient(recipient);

    // Update URL with selected user (shallow)
    const searchParams = new URLSearchParams(location.search);
    if (recipient) {
      searchParams.set('user', recipient.id);
    } else {
      searchParams.delete('user');
    }

    // Update URL without page reload
    window.history.replaceState(
        {},
        '',
        `${location.pathname}${searchParams.toString() ? '?' + searchParams.toString() : ''}`,
    );
  };

  // Add scroll after messages load
  useEffect(() => {
    if (!isLoading && messages.length > 0) {
      scrollToBottom();
    }
  }, [messages, isLoading, scrollToBottom]);

  const handleSendMessage = async () => {
    if (!messageText.trim() || !selectedRecipient) return;

    setIsSending(true);
    try {
      await sendMessage(projectId, {
        title: messageTitle,
        text: messageText,
        recipientId: selectedRecipient.id,
      });

      // Clear inputs and refresh messages on success
      setMessageTitle('');
      setMessageText('');
      await fetchMessages();
      scrollToBottom();
    } catch (error) {
      console.error('Failed to send message:', error);
    } finally {
      setIsSending(false);
    }
  };

  const isMessageFromCurrentUser = (message) => {
    return message.senderId === user.id;
  };

  const getMessageSenderName = (message) => {
    if (isMessageFromCurrentUser(message)) return 'YOU';
    return `${message.senderFirstname} ${message.senderLastname}`.trim() || 'Unknown User';
  };

  const getRecipientDisplayName = (recipient) => {
    if (!recipient) return '';
    return `${recipient.recipientFirstname} ${recipient.recipientLastname}`.trim() || recipient.email;
  };

  return (
    <div className="Messages">
      <div className="Messages-contacts">
        {recipients.map((recipient) => (
          <div
            key={recipient.id}
            className={`Messages-contact ${selectedRecipient?.id === recipient.id ? 'active' : ''}`}
            onClick={() => handleRecipientSelect(recipient)}
          >
            <div className="contact-name">
              {`${recipient.firstname} ${recipient.lastname}`.trim() || 'Unknown User'}
            </div>
            <div className="contact-email">{recipient.email}</div>
          </div>
        ))}
      </div>

      <div className="Messages-chat">
        <div className="Messages-history" ref={messagesContainerRef}>
          {!selectedRecipient ? (
            <div className="no-recipient">Select a recipient to view messages</div>
          ) : isLoading ? (
            <div className="loading-message">Loading messages...</div>
          ) : messages.length === 0 ? (
            <div className="no-messages">No messages yet</div>
          ) : (
            <>
              {messages.map((message) => (
                <div
                  key={message.id}
                  className={`message ${isMessageFromCurrentUser(message) ? 'sent' : 'received'}`}
                >
                  <div className="message-header">
                    <span className="sender-name">{getMessageSenderName(message)}</span>
                    {message.title && (
                      <span className="message-title">"{message.title}"</span>
                    )}
                    {/* <span className="message-time">
                      {new Date(message.createdAt).toLocaleString()}
                    </span> */}
                  </div>
                  <div className="message-content">
                    <div className="message-text">{message.text}</div>
                  </div>
                </div>
              ))}
              {isRefetching && (
                <div className="refetching-indicator">
                  <CircularProgress size={20} />
                </div>
              )}
            </>
          )}
        </div>

        <div className="Messages-input">
          <div className="input-container">
            <div className="input-group">
              <input
                type="text"
                value={messageTitle}
                onChange={(e) => setMessageTitle(e.target.value)}
                placeholder={t('input.title_placeholder')}
                disabled={!selectedRecipient}
              />
              <textarea
                value={messageText}
                onChange={(e) => setMessageText(e.target.value)}
                placeholder={selectedRecipient ? t('input.message_placeholder') : 'Select a recipient to send a message'}
                disabled={!selectedRecipient}
              />
            </div>
            <div className="button-container">
              <Button
                variant="contained"
                onClick={handleSendMessage}
                disabled={!messageText.trim() || !selectedRecipient || isSending}
              >
                {isSending ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  t('send')
                )}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

Messages.propTypes = {
  projectId: PropTypes.string.isRequired,
};
