import './ContactRequests.scss';

import * as React from 'react';
import {useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

import IndexPageHeader from '../../components/IndexPageHeader/IndexPageHeader';
import IndexTableManaged from '../../components/IndexTable/IndexTableManaged';
import DefaultLayout from '../../layouts/DefaultLayout/DefaultLayout';
import changeRequestStatus from './api/changeRequestStatus';
import getPendingInvestments from './api/getPendingInvestments';

export default function ContactRequests() {
  const {t} = useTranslation('page_available_investments');
  const [data, setData] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const currentPageSize = 10;
  const [currentPage, setCurrentPage] = useState(1);

  const headers = [
    {
      id: 'projectId',
      label: '#ID',
      width: '80px',
    },
    {
      id: 'description',
      label: t('table.header.description'),
      style: {
        minWidth: '300px',
        width: '30%',
      },
    },
    {
      id: 'status',
      label: t('table.header.status'),
      width: '120px',
    },
  ];

  const refreshData = useCallback(() => {
    setIsLoading(true);

    getPendingInvestments(currentPage, currentPageSize)
        .then(async (response) => {
          const formattedData = await formatTableData(response['hydra:member']);
          setData(formattedData);
          setTotalItems(response['hydra:totalItems']);
        })
        .catch((error) => {
          console.error('Failed to fetch contact requests:', error);
        })
        .finally(() => {
          setIsLoading(false);
        });
  }, [currentPage, currentPageSize, t]);

  const formatTableData = (apiData) => {
    return apiData.map((item) => {
      const getStatusStyle = (status) => {
        switch (status) {
          case 'approved':
            return {color: '#10b981'}; // green
          case 'rejected':
            return {color: '#ef4444'}; // red
          case 'pending':
          default:
            return {color: '#f59e0b'}; // amber
        }
      };

      const getStatusLabel = (status) => {
        switch (status) {
          case 'approved':
            return 'Approved';
          case 'rejected':
            return 'Rejected';
          case 'pending':
          default:
            return 'Pending';
        }
      };

      return {
        id: item.id.toString(),
        projectId: item.id,
        fields: [
          {
            label: `#${item.id}`,
          },
          {
            label: item.description?.trim() ? item.description : t('table.no_description'),
            noWrap: true,
            style: {
              maxWidth: '300px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              color: !item.description?.trim() ? '#666' : 'inherit',
            },
          },
          {
            label: getStatusLabel(item.pendingStatus),
            style: {
              ...getStatusStyle(item.pendingStatus),
              fontWeight: 500,
            },
          },
        ],
        moreMenu: item.pendingStatus === 'pending' ?
          [
            {
              id: 'approve',
              text: 'Approve',
            },
            {
              id: 'reject',
              text: 'Reject',
            },
          ] :
          [
            {
              id: 'view',
              text: 'View details',
            },
          ],
      };
    });
  };

  async function onMoreActionClick(actionId, rowIndex) {
    // Only process approve/reject actions
    if (actionId !== 'approve' && actionId !== 'reject') return;

    const request = data[rowIndex];
    if (!request) return;

    try {
      const status = actionId === 'approve' ? 'approved' : 'rejected';
      await changeRequestStatus(request.projectId, status);
      refreshData();
    } catch (error) {
      console.error('Failed to change request status:', error);
    }
  }

  function onPageChangeClick(newPageIndex) {
    setCurrentPage(newPageIndex + 1);
  }

  useEffect(() => {
    refreshData();
  }, [currentPage, refreshData]);

  return (
    <DefaultLayout>
      <div className="ContactRequests">
        <IndexPageHeader text="Contact Requests" />
        <IndexTableManaged
          headers={headers}
          rows={data}
          pagination={{
            page: currentPage - 1,
            rowsPerPage: currentPageSize,
            items: totalItems,
          }}
          isLoading={isLoading}
          onPageChangeClick={onPageChangeClick}
          onMoreActionClick={onMoreActionClick}
        />
      </div>
    </DefaultLayout>
  );
}
