import {useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

import getPendingInvestments from '../api/getPendingInvestments';
import {formatTableRow} from '../utils/helpers';

export const useInvestments = (page, pageSize) => {
  const [data, setData] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const {t} = useTranslation('page_available_investments');

  const refreshData = useCallback(() => {
    setIsLoading(true);
    getPendingInvestments(page, pageSize)
        .then((response) => {
          setData(response['hydra:member'].map((item) => formatTableRow(item, t)));
          setTotalItems(response['hydra:totalItems']);
        })
        .catch((error) => {
          console.error('Failed to fetch investments:', error);
        })
        .finally(() => {
          setIsLoading(false);
        });
  }, [page, pageSize, t]);

  useEffect(() => {
    refreshData();
  }, [refreshData]);

  return {data, totalItems, isLoading, refreshData};
};
