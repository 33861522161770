import {ROLE_ADMIN, ROLE_INVESTOR, ROLE_SALES, ROLE_SUPERADMIN, ROLE_USER, ROLE_VIEWONLY} from '../security/listAllRoles';

const ALLOWED_ROLE_NAMES = [
  ROLE_SUPERADMIN,
  ROLE_ADMIN,
  ROLE_SALES,
  ROLE_VIEWONLY,
  ROLE_USER,
  ROLE_INVESTOR,
];

export default function isValidUserRole(str) {
  return ALLOWED_ROLE_NAMES.indexOf(str) > -1;
}
