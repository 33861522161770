import Button from '@mui/material/Button';
import React, {useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useLocation, useNavigate, useParams} from 'react-router-dom';

import DefaultLayout from '../../layouts/DefaultLayout/DefaultLayout';
import SingleEntityLayout from '../../layouts/SingleEntityLayout/SingleEntityLayout';
import postSetInterest from '../AvailableInvestments/api/postSetInterest';
import Messages from '../Messages/Messages';
import ProjectDocumentsPanel from '../ProjectDetailsPage/ProjectDocumentsPanel';
import BreadcrumbNavigation from '../ProjectDetailsPage/ProjectEdit/BreadcrumbNavigation';
import ProjectRecentActivityPanel from '../ProjectDetailsPage/ProjectRecentActivityPanel';
import getInvestmentDetails from './api/getInvestmentDetails';
import css from './InvestmentDetails.module.scss';

function LimitedAccessPanel({investment, onExpressInterest}) {
  const {t} = useTranslation('page_available_investments');

  function formatMoney(amount, currency = 'EUR') {
    if (!amount) return 'TBD';
    return new Intl.NumberFormat('nl-NL', {
      style: 'currency',
      currency: currency,
    }).format(amount);
  }

  function formatDate(dateString) {
    if (!dateString) return '-';
    return new Date(dateString).toLocaleDateString();
  }

  const isPending = !!investment?.createdAt;

  return (
    <div className={css.overviewPanel}>
      <BreadcrumbNavigation />
      <div className={css.overviewContent}>
        <h2>{t('panels.overview')}</h2>

        <div className={css.field}>
          <label>{t('table.header.project_id')}</label>
          <div>Project {investment?.id}</div>
        </div>

        <div className={css.description}>
          <h3>{t('panels.description')}</h3>
          <p>{investment?.descriptionForInvestors || t('table.no_description')}</p>

          {!isPending && (
            <Button
              variant="contained"
              onClick={onExpressInterest}
            >
              {t('table.actions.express_interest')}
            </Button>
          )}
        </div>

        <div className={css.field}>
          <label>{t('table.header.country')}</label>
          <div style={{color: '#666'}}>{investment?.country || '-'}</div>
        </div>

        <div className={css.field}>
          <label>{t('table.header.city')}</label>
          <div style={{color: '#666'}}>{investment?.city || '-'}</div>
        </div>

        <div className={css.field}>
          <label>{t('table.header.required_amount')}</label>
          <div>{formatMoney(investment?.amount, investment?.amountCurrency)}</div>
        </div>

        <div className={css.field}>
          <label>{t('table.header.status')}</label>
          <div style={{
            color: isPending ? '#f59e0b' : '#10b981',
            fontWeight: 500,
          }}>
            {isPending ? 'Pending' : 'Available'}
          </div>
        </div>

        <div className={css.field}>
          <label>{t('table.header.last_updated')}</label>
          <div>{formatDate(investment?.updatedAt)}</div>
        </div>

        <div className={css.placeholderSection}>
          <div className={css.placeholderIcon}>🔒</div>
          <h4>{t('panels.financial_details')}</h4>
          <p>{t('messages.locked_section')}</p>
        </div>
      </div>
    </div>
  );
}

function PlaceholderPanel({title}) {
  const {t} = useTranslation('page_available_investments');

  return (
    <div className={css.panel}>
      <h2>{title}</h2>
      <div className={css.placeholderSection}>
        <div className={css.placeholderIcon}>🔒</div>
        <p>{t('messages.locked_section')}</p>
      </div>
    </div>
  );
}

function FullAccessPanel({investment}) {
  const {t} = useTranslation('layout');

  function formatMoney(amount, currency = 'EUR') {
    if (!amount) return '-';
    return new Intl.NumberFormat('nl-NL', {
      style: 'currency',
      currency: currency,
    }).format(amount);
  }

  function formatDate(dateString) {
    if (!dateString) return '-';
    return new Date(dateString).toLocaleDateString();
  }

  const primaryUser = investment?.primaryProjectUser;
  const handler = investment?.handler?.display;
  const client = investment?.projectCompanies?.find((pc) => pc.isClient)?.company;
  const moneySupplier = investment?.moneySuppliers?.[0]?.company;
  const intermediary = investment?.projectCompanies?.find((pc) => pc.isIntermediary)?.company;

  return (
    <div className={css.overviewPanel}>
      <BreadcrumbNavigation />
      <div className={css.overviewContent}>
        <h2>{t('overview.header')}</h2>

        {/* Basic Investment Details */}
        <div className={css.field}>
          <label>DESCRIPTION</label>
          <div>{investment?.descriptionForInvestors || '-'}</div>
        </div>
        <div className={css.field}>
          <label>COUNTRY</label>
          <div>{investment?.country || '-'}</div>
        </div>
        <div className={css.field}>
          <label>CITY</label>
          <div>{investment?.city || '-'}</div>
        </div>
        <div className={css.field}>
          <label>REQUIRED AMOUNT</label>
          <div>{formatMoney(investment?.amount, investment?.amountCurrency)}</div>
        </div>
        <div className={css.field}>
          <label>STATUS</label>
          <div style={{
            color: investment?.status?.toLowerCase() === 'pending' ? '#f59e0b' : '#10b981',
            fontWeight: 500,
          }}>
            {(investment?.status || '-').charAt(0).toUpperCase() + (investment?.status || '-').slice(1).toLowerCase()}
          </div>
        </div>
        <div className={css.field}>
          <label>LAST UPDATED</label>
          <div>{formatDate(investment?.lastUpdatedAt)}</div>
        </div>

        {/* Detailed Information Section */}
        <div className={css.section}>
          <h3>Additional Information</h3>

          <div className={css.field}>
            <label>FIRST NAME</label>
            <div>{primaryUser?.firstname || '-'}</div>
          </div>
          <div className={css.field}>
            <label>LAST NAME</label>
            <div>{primaryUser?.lastname || '-'}</div>
          </div>
          <div className={css.field}>
            <label>COMPANY NAME</label>
            <div>{client?.name || '-'}</div>
          </div>
          <div className={css.field}>
            <label>PHONE NUMBER</label>
            <div>{primaryUser?.primaryPhoneDisplay || '-'}</div>
          </div>
          <div className={css.field}>
            <label>TYPE OF PITCH</label>
            <div>{investment?.internalName || '-'}</div>
          </div>
          <div className={css.field}>
            <label>ACTIVITY</label>
            <div>{investment?.activities || '-'}</div>
          </div>
          <div className={css.field}>
            <label>HANDLER</label>
            <div>{handler || '-'}</div>
          </div>
          <div className={css.field}>
            <label>INTERMEDIARY</label>
            <div>{intermediary?.name || '-'}</div>
          </div>
          <div className={css.field}>
            <label>MONEY SUPPLIER</label>
            <div>{moneySupplier?.name || '-'}</div>
          </div>
          <div className={css.field}>
            <label>CREATED AT</label>
            <div>{formatDate(investment?.createdAt)}</div>
          </div>
          <div className={css.field}>
            <label>BECAME OPPORTUNITY</label>
            <div>{formatDate(investment?.becameOpportunityAt)}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

function PaymentMandatesPanel() {
  const {t} = useTranslation('page_available_investments');
  return (
    <div className={css.panel}>
      <h2>{t('panels.payment_mandates')}</h2>
      {/* <div className={css.mandate}>
        <div className={css.mandateInfo}>
          <div className={css.mandateName}>Mandate 1</div>
          <div className={css.mandateId}>#2012343</div>
        </div>
        <div className={css.mandateAmount}>€24,000</div>
      </div>
      <div className={css.mandate}>
        <div className={css.mandateInfo}>
          <div className={css.mandateName}>Confirmation Payment</div>
          <div className={css.mandateId}>#2012343</div>
        </div>
        <div className={css.mandateAmount}>€1,000</div>
      </div> */}
      <div className={css.notYetImplemented}>
        NOT YET IMPLEMENTED
      </div>
    </div>
  );
}

export default function InvestmentDetails() {
  const {id} = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [investment, setInvestment] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const {t} = useTranslation('page_available_investments');

  const hasLimitedAccess = location.pathname.startsWith('/available-investments/');

  const handleExpressInterest = useCallback(async () => {
    try {
      setIsLoading(true);
      await postSetInterest(id);
      navigate('/my-investments');
    } catch (error) {
      console.error('Failed to express interest:', error);
      setIsLoading(false);
    }
  }, [id, navigate]);

  console.log(investment);

  useEffect(() => {
    const fetchInvestment = async () => {
      setIsLoading(true);
      try {
        const isMyInvestment = location.pathname.startsWith('/my-investments/');
        const data = await getInvestmentDetails(id, isMyInvestment);
        setInvestment(data);
      } catch (error) {
        console.error('Failed to fetch investment details:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchInvestment();
  }, [id, location]);

  if (isLoading) {
    return <DefaultLayout><div>Loading...</div></DefaultLayout>;
  }

  return (
    <DefaultLayout>
      <SingleEntityLayout
        leftPanel={
          hasLimitedAccess ?
            <LimitedAccessPanel
              investment={investment}
              onExpressInterest={handleExpressInterest}
            /> :
            <FullAccessPanel investment={investment} />
        }
      >
        <div className={css.mainContent}>
          <div className={css.topPanels}>
            {hasLimitedAccess ? (
              <>
                <PlaceholderPanel title={t('panels.recent_activity')} />
                <PlaceholderPanel title={t('panels.payment_mandates')} />
              </>
            ) : (
              <>
                <ProjectRecentActivityPanel project={investment} />
                <PaymentMandatesPanel />
              </>
            )}
          </div>
          <div className={css.messagesPanel}>
            {hasLimitedAccess ? (
              <PlaceholderPanel title={t('panels.messages')} />
            ) : (
              <Messages
                projectId={id}
                contacts={investment?.projectUsers || []}
              />
            )}
          </div>
          <div className={css.documentsPanel}>
            {hasLimitedAccess ? (
              <PlaceholderPanel title={t('panels.documents')} />
            ) : (
              <ProjectDocumentsPanel project={investment} />
            )}
          </div>
        </div>
      </SingleEntityLayout>
    </DefaultLayout>
  );
}
