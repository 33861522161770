import Api from '../../../helpers/api/Api';

export default function getPendingInvestments(page = 1, itemsPerPage = 10) {
  const queryParams = new URLSearchParams({
    page: page.toString(),
    itemsPerPage: itemsPerPage.toString(),
  });

  return new Promise(function(resolve, reject) {
    Api.get(`/pending_investments?${queryParams.toString()}`)
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
  });
}
