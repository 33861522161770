import Api from '../../../helpers/api/Api';

export default function postSetInterest(projectId) {
  return new Promise(function(resolve, reject) {
    Api.post(`/projects/${projectId}/set_interest`, {
      pendingStatus: 'pending',
    })
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
  });
}
