import {Typography} from '@mui/material';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useLocation, useNavigate} from 'react-router-dom';

import css from './index.module.scss';

function BreadcrumbNavigation() {
  const {t} = useTranslation('page_cases');
  const navigate = useNavigate();
  const location = useLocation();

  const urlParts = location.pathname.split('/').filter((item)=>(!!item));
  const navigationTarget = urlParts.length >= 2 && !isNaN(parseInt(urlParts[1])) ? `/${urlParts[0]}` : null;

  function navigateBack() {
    navigate(navigationTarget ?? -1);
  }

  return (
    <div className={css.BreadcrumbNavigation} onClick={navigateBack}>
      <div className={css.arrowBack} />
      <Typography fontSize={14}>
        {navigationTarget === '/cases' && t('page.case_details.back')}
        {navigationTarget === '/leads' && t('page.lead_details.back')}
        {navigationTarget === '/opportunities' && t('page.opportunity_details.back')}
        {navigationTarget === '/accounts' && t('page.account_details.back')}
        {navigationTarget === '/available-investments' && t('page.investment_details.back')}
        {navigationTarget === '/my-investments' && t('page.my_investment_details.back')}
      </Typography>
    </div>
  );
}

export default BreadcrumbNavigation;
