import {useCallback, useMemo} from 'react';
import {useSearchParams} from 'react-router-dom';

import listAllRoles, {ROLE_ADMIN, ROLE_INVESTOR, ROLE_SALES, ROLE_SUPERADMIN, ROLE_VIEWONLY} from '../../../helpers/security/listAllRoles';

export const PARAM_PAGE = 'page';
export const PARAM_ITEMS_PER_PAGE = 'itemsPerPage';
export const PARAM_SEARCH = 'search';
export const PARAM_ORDER_BY = 'orderBy';
export const PARAM_ORDER_DIR = 'orderDir';
export const PARAM_SECURITY_CONTEXT = 'securityContext';
export const PARAM_GROUP = 'group';
export const PARAM_ACTIVE = 'onlyActive';

export const GROUP = {
  ALL: 'all',
  ADMINISTRATORS: 'admin',
  SALES: 'sales',
  VIEWONLY: 'viewonly',
};

export const PARAMS_DEFAULTS = {
  [PARAM_PAGE]: {default: 1, type: 'integer'},
  [PARAM_ITEMS_PER_PAGE]: {default: 10, type: 'integer'},
  [PARAM_SEARCH]: {default: ''},
  [PARAM_ORDER_BY]: {default: 'display'},
  [PARAM_ORDER_DIR]: {default: 'asc'},
  [PARAM_SECURITY_CONTEXT]: {default: null, type: 'integer'},
  [PARAM_GROUP]: {default: GROUP.ALL},
  [PARAM_ACTIVE]: {default: true, type: 'boolean'},
};
export default function useUsersListFilters() {
  const [urlParams, setUrlParams] = useSearchParams();

  const _PARAM_PAGE = normalizedValue(PARAM_PAGE, urlParams);
  const _PARAM_ITEMS_PER_PAGE = normalizedValue(PARAM_ITEMS_PER_PAGE, urlParams);
  const _PARAM_SEARCH = normalizedValue(PARAM_SEARCH, urlParams);
  const _PARAM_ORDER_BY = normalizedValue(PARAM_ORDER_BY, urlParams);
  const _PARAM_ORDER_DIR = normalizedValue(PARAM_ORDER_DIR, urlParams);
  const _PARAM_SECURITY_CONTEXT = normalizedValue(PARAM_SECURITY_CONTEXT, urlParams);
  const _PARAM_GROUP = normalizedValue(PARAM_GROUP, urlParams);
  const _PARAM_ACTIVE = normalizedValue(PARAM_ACTIVE, urlParams);

  const current = useMemo(()=>{
    return {
      [PARAM_PAGE]: _PARAM_PAGE,
      [PARAM_ITEMS_PER_PAGE]: _PARAM_ITEMS_PER_PAGE,
      [PARAM_SEARCH]: _PARAM_SEARCH,
      [PARAM_ORDER_BY]: _PARAM_ORDER_BY,
      [PARAM_ORDER_DIR]: _PARAM_ORDER_DIR,
      [PARAM_SECURITY_CONTEXT]: _PARAM_SECURITY_CONTEXT,
      [PARAM_GROUP]: _PARAM_GROUP,
      [PARAM_ACTIVE]: _PARAM_ACTIVE,
    };
  }, [_PARAM_ACTIVE, _PARAM_GROUP, _PARAM_ITEMS_PER_PAGE, _PARAM_ORDER_BY, _PARAM_ORDER_DIR, _PARAM_PAGE, _PARAM_SEARCH, _PARAM_SECURITY_CONTEXT]);

  const setParams = useCallback((params)=>{
    const newUrlParams = new URLSearchParams(urlParams);
    let changed = false;
    for (const paramName in params) {
      if (current[paramName] !== params[paramName]) {
        if (params[paramName] === PARAMS_DEFAULTS[paramName].default) {
          newUrlParams.delete(paramName);
        } else {
          newUrlParams.set(paramName, params[paramName]);
        }
        changed=true;
      }
    }
    if (changed) {
      setUrlParams(newUrlParams);
    }
  }, [current, setUrlParams, urlParams]);

  const currentFetchUrl = useMemo(()=>{
    function isDefault(paramName) {
      return PARAMS_DEFAULTS[paramName] && current && current[paramName] === PARAMS_DEFAULTS[paramName].default;
    }
    function nullIfDefault(paramName, queryPart) {
      return isDefault(paramName) ? null : queryPart;
    }
    function groupNameToRoles(groupName) {
      switch (groupName) {
        case GROUP.ALL:
          return [ROLE_SUPERADMIN, ROLE_ADMIN, ROLE_SALES, ROLE_VIEWONLY];
        case GROUP.ADMINISTRATORS:
          return [ROLE_SUPERADMIN, ROLE_ADMIN];
        case GROUP.SALES:
          return [ROLE_SALES];
        case GROUP.VIEWONLY:
          return [ROLE_VIEWONLY];
        case GROUP.INVESTOR:
          return [ROLE_INVESTOR];
        default:
          return listAllRoles();
      }
    }
    function orderToUrlParams(byName, dir) {
      switch (byName) {
        case 'display':
          return `order[lastname]=${dir}&order[firstname]=${dir}`;
        default:
          return `order[${byName}]=${dir}`;
      }
    }
    return [
      `${PARAM_PAGE}=${_PARAM_PAGE}`,
      `${PARAM_ITEMS_PER_PAGE}=${_PARAM_ITEMS_PER_PAGE}`,
      nullIfDefault(PARAM_SEARCH, `${PARAM_SEARCH}=${_PARAM_SEARCH}`),
      orderToUrlParams(_PARAM_ORDER_BY, _PARAM_ORDER_DIR),
      nullIfDefault(PARAM_SECURITY_CONTEXT, `${PARAM_SECURITY_CONTEXT}=${_PARAM_SECURITY_CONTEXT}`),
      groupNameToRoles(_PARAM_GROUP).map((r)=>`roles[]=${r}`).join('&'),
      _PARAM_ACTIVE ? `${PARAM_ACTIVE}=1` : null,
    ].filter((part) => part!== null).join('&');
  }, [
    _PARAM_ACTIVE,
    _PARAM_GROUP,
    _PARAM_ITEMS_PER_PAGE,
    _PARAM_ORDER_BY,
    _PARAM_ORDER_DIR,
    _PARAM_PAGE,
    _PARAM_SEARCH,
    _PARAM_SECURITY_CONTEXT,
    current,
  ]);

  return {
    current,
    setParams,
    currentFetchUrl,
  };
}

function normalizedValue(paramName, urlParams) {
  if (typeof PARAMS_DEFAULTS[paramName] === 'undefined') {
    return null;
  } else {
    const strVal = urlParams.has(paramName) ? urlParams.get(paramName) : PARAMS_DEFAULTS[paramName].default;
    const type = PARAMS_DEFAULTS[paramName]?.type ?? 'string';
    switch (type) {
      case 'int':
      case 'integer':
        return !isNaN(parseInt(strVal)) ? parseInt(strVal) : null;
      case 'bool':
      case 'boolean':
        if (strVal==='true' || strVal === '1') {
          return true;
        } else if (strVal==='false' || strVal === '0') {
          return false;
        } else {
          return PARAMS_DEFAULTS[paramName].default;
        }
      default:
        return strVal;
    }
  }
}


