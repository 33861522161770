import './AvailableInvestments.scss';

import * as React from 'react';
import {useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';

import IndexPageHeader from '../../components/IndexPageHeader/IndexPageHeader';
import IndexTableManaged from '../../components/IndexTable/IndexTableManaged';
import DefaultLayout from '../../layouts/DefaultLayout/DefaultLayout';
import getAllowedInvestments from './api/getAllowedInvestments';
import postSetInterest from './api/postSetInterest';

export default function AvailableInvestments() {
  const {t} = useTranslation('page_available_investments');
  const [data, setData] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const currentPageSize = 10;
  const [currentPage, setCurrentPage] = useState(1);

  const navigate = useNavigate();

  const headers = [
    {
      id: 'projectId',
      label: t('table.header.project_id'),
      width: '120px',
    },
    {
      id: 'description',
      label: t('table.header.description'),
      style: {
        minWidth: '300px',
        width: '30%',
      },
    },
    {
      id: 'country',
      label: t('table.header.country'),
      width: '120px',
    },
    {
      id: 'city',
      label: t('table.header.city'),
      width: '120px',
    },
    {
      id: 'requiredAmount',
      label: t('table.header.required_amount'),
      width: '180px',
    },
    {
      id: 'status',
      label: t('table.header.status'),
      width: '120px',
    },
    {
      id: 'lastUpdated',
      label: t('table.header.last_updated'),
      width: '150px',
    },
  ];

  function formatDate(dateString) {
    if (!dateString) return '';
    return new Date(dateString).toLocaleDateString();
  }

  function formatMoney(amount, currency = 'EUR') {
    if (!amount) return 'TBD';
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(amount);
  }

  const refreshData = useCallback(() => {
    setIsLoading(true);

    function formatTableData(apiData) {
      return apiData.map((item) => {
        const hasExpressedInterest = !!item.createdAt;

        return {
          id: item.id.toString(),
          fields: [
            {label: `Project ${item.id}` || ''},
            {
              label: item.descriptionForInvestors?.trim() ? item.descriptionForInvestors : t('table.no_description'),
              noWrap: true,
              style: {
                maxWidth: '300px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                color: !item.descriptionForInvestors?.trim() ? '#666' : 'inherit',
              },
            },
            {
              label: item.country || '-',
              style: {
                color: !item.country ? '#666' : 'inherit',
              },
            },
            {
              label: item.city || '-',
              style: {
                color: !item.city ? '#666' : 'inherit',
              },
            },
            {
              label: formatMoney(item.amount, item.amountCurrency),
              style: {
                color: !item.amount ? '#666' : 'inherit',
                whiteSpace: 'nowrap',
              },
            },
            {
              label: hasExpressedInterest ? 'Pending' : 'Available',
              style: {
                color: hasExpressedInterest ? '#f59e0b' : '#10b981',
                fontWeight: 500,
              },
            },
            {
              label: item.updatedAt ? formatDate(item.updatedAt) : '-',
              style: {
                color: item.updatedAt ? 'inherit' : '#666',
              },
            },
          ],
          moreMenu: [
            {
              id: 'details',
              text: t('table.actions.details'),
            },
            ...(!hasExpressedInterest ? [{
              id: 'express_interest',
              text: t('table.actions.express_interest'),
            }] : []),
          ],
          style: {
            cursor: 'default',
          },
        };
      });
    }

    getAllowedInvestments(currentPage, currentPageSize)
        .then((response) => {
          const formattedData = formatTableData(response['hydra:member']);
          setData(formattedData);
          setTotalItems(response['hydra:totalItems']);
        })
        .catch((error) => {
          console.error('Failed to fetch investments:', error);
        })
        .finally(() => {
          setIsLoading(false);
        });
  }, [currentPage, currentPageSize, t]);

  function onMoreActionClick(actionId, rowIndex) {
    const investment = data[rowIndex];
    if (!investment) return;

    switch (actionId) {
      case 'details':
        navigate(`/available-investments/${investment.id}`);
        break;
      case 'express_interest':
        setIsLoading(true);
        postSetInterest(investment.id)
            .then(() => {
              refreshData();
            })
            .catch((error) => {
              console.error('Failed to express interest:', error);
              setIsLoading(false);
            });
        break;
    }
  }

  function onPageChangeClick(newPageIndex) {
    setCurrentPage(newPageIndex + 1);
  }

  useEffect(() => {
    refreshData();
  }, [currentPage, refreshData]);

  return (
    <DefaultLayout>
      <div className="AvailableInvestments">
        <IndexPageHeader text={t('page.title.available')} />
        <IndexTableManaged
          headers={headers}
          rows={data}
          pagination={{
            page: currentPage - 1,
            rowsPerPage: currentPageSize,
            items: totalItems,
          }}
          isLoading={isLoading}
          onPageChangeClick={onPageChangeClick}
          onMoreActionClick={onMoreActionClick}
        />
      </div>
    </DefaultLayout>
  );
}
