import './MyInvestments.scss';

import * as React from 'react';
import {useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';

import IndexPageHeader from '../../components/IndexPageHeader/IndexPageHeader';
import IndexTableManaged from '../../components/IndexTable/IndexTableManaged';
import DefaultLayout from '../../layouts/DefaultLayout/DefaultLayout';
import {useInvestments} from './hooks/useInvestments';

const PAGE_SIZE = 10;

const createTableHeaders = (t) => ([
  {
    id: 'projectId',
    label: t('table.header.project_id'),
    width: '120px',
  },
  {
    id: 'description',
    label: t('table.header.description'),
    style: {
      minWidth: '300px',
      width: '30%',
    },
  },
  {
    id: 'status',
    label: t('table.header.status'),
    width: '120px',
  },
  {
    id: 'lastUpdated',
    label: t('table.header.last_updated'),
    width: '150px',
  },
]);

export default function MyInvestments() {
  const {t} = useTranslation('page_available_investments');
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();
  const headers = useMemo(() => createTableHeaders(t), [t]);

  const {data, totalItems, isLoading} = useInvestments(currentPage, PAGE_SIZE);

  const onMoreActionClick = (actionId, rowIndex) => {
    const investment = data[rowIndex];
    if (!investment?.projectId) return;

    if (actionId === 'details' && investment.pendingStatus === 'approved') {
      navigate(`/my-investments/${investment.projectId}`);
    }
  };

  return (
    <DefaultLayout>
      <div className="MyInvestments">
        <IndexPageHeader text={t('page.title.my_investments')} />
        <IndexTableManaged
          headers={headers}
          rows={data}
          pagination={{
            page: currentPage - 1,
            rowsPerPage: PAGE_SIZE,
            items: totalItems,
          }}
          isLoading={isLoading}
          onPageChangeClick={(newPageIndex) => setCurrentPage(newPageIndex + 1)}
          onMoreActionClick={onMoreActionClick}
        />
      </div>
    </DefaultLayout>
  );
}
