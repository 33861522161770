import LoginLayout from '../../layouts/LoginLayout';
import {CompanySelfRegistrationForm} from './CompanySelfRegistrationForm';

export const CompanySelfRegistrationPage = () => {
  return (
    <LoginLayout>
      <CompanySelfRegistrationForm />
    </LoginLayout>
  );
};
