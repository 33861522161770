import Api from '../../../helpers/api/Api';

export default function getInvestmentDetails(id, isMyInvestment) {
  if (isMyInvestment) {
    return Api.get(`/projects/${id}`);
  }

  // For available investments, fetch all and find the matching one
  return new Promise((resolve, reject) => {
    Api.get('/projects/investments_allowed')
        .then((response) => {
          const investment = response['hydra:member']?.find((inv) => inv.id.toString() === id.toString());
          if (investment) {
            resolve(investment);
          } else {
            reject(new Error('Investment not found'));
          }
        })
        .catch((error) => {
          reject(error);
        });
  });
}
