export const ACTION_SET_USER = 'ACTION_SET_USER';
export const ACTION_SET_USER_FIELD = 'ACTION_SET_USER_FIELD';
export const ACTION_SET_UNREAD_NOTIFICATIONS_COUNT = 'ACTION_SET_UNREAD_NOTIFICATIONS_COUNT';

export function setUser(user) {
  return {
    type: ACTION_SET_USER,
    data: {
      ...user,
      'roles': [...user.roles, ...(user.isInvestor ? ['ROLE_INVESTOR'] : [])],
      'primaryRole': user.isInvestor ? 'ROLE_INVESTOR' : user.primaryRole,
      // ! DEBUG VALUES !
      // 'isInvestor': true,
      // 'roles': ['ROLE_INVESTOR', 'ROLE_USER'],
      // 'primaryRole': 'ROLE_INVESTOR',
      // ! DEBUG VALUES !
      '@id': `/api/users/${user.id}`,
      '@type': 'User',
    },
  };
}

export function setUserField(field, value) {
  return {
    type: ACTION_SET_USER_FIELD,
    data: {field, value},
  };
}

export function setUnreadNotificationsCount(count) {
  return {type: ACTION_SET_UNREAD_NOTIFICATIONS_COUNT, data: count};
}
