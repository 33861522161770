import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import {initReactI18next} from 'react-i18next';

const isDevelopment = Boolean(!process.env.NODE_ENV || process.env.NODE_ENV === 'development');

i18n
    .use(Backend)
    .use(initReactI18next)
    .init({
      ns: [
        'component_add_company',
        'component_add_project',
        'component_documents',
        'component_editfield',
        'component_filter_dropdown',
        'component_notes',
        'component_notifications',
        'component_recent_activity',
        'component_related_projects',
        'component_table',
        'component_tasks',
        'dictionary_company_type',
        'dictionary_legal_form',
        'dictionary_project_status',
        'dictionary_project_type',
        'dictionary_user_role',
        'dictionary_user_status',
        'dictionary_tags',
        'formatting',
        'layout',
        'login',
        'page_cases',
        'page_companies',
        'page_notifications',
        'page_overview',
        'page_tasks',
        'page_settings',
        'page_users',
        'page_available_investments',
        'page_contact_requests',
        'self_registration',
      ],
      fallbackLng: ['nl', 'en'],
      debug: false,
      // debug: isDevelopment,
      appendNamespaceToCIMode: true,
    });

export default i18n;
