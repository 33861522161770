import i18n from '../../i18n';
import {ROLE_ADMIN, ROLE_INVESTOR, ROLE_SALES, ROLE_SUPERADMIN, ROLE_USER, ROLE_VIEWONLY} from '../security/listAllRoles';

export default function role2display(role) {
  switch (role) {
    case ROLE_SUPERADMIN: return i18n.t(ROLE_SUPERADMIN, {ns: 'dictionary_user_role'});
    case ROLE_ADMIN: return i18n.t(ROLE_ADMIN, {ns: 'dictionary_user_role'});
    case ROLE_SALES: return i18n.t(ROLE_SALES, {ns: 'dictionary_user_role'});
    case ROLE_VIEWONLY: return i18n.t(ROLE_VIEWONLY, {ns: 'dictionary_user_role'});
    case ROLE_USER: return i18n.t(ROLE_USER, {ns: 'dictionary_user_role'});
    case ROLE_INVESTOR: return i18n.t(ROLE_INVESTOR, {ns: 'dictionary_user_role'});
    default: return '';
  }
}
