import {Checkbox, FormControlLabel} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

export default function EditFieldCheckbox(props) {
  function callback(event) {
    props.onChange(event.target.checked);
  }

  return (
    <FormControlLabel control={<Checkbox
      className="EditFieldString"
      checked={props.value}
      onChange={callback}
      error={props.error}
    />} label={props.name}/>
  );
}

EditFieldCheckbox.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  icon: PropTypes.element,
  name: PropTypes.string,
};
